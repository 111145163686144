/*Skin*/
.site-header {
  position: relative; }
  .site-header .hfg-container {
    display: flex;
    flex-direction: column;
    justify-content: center; }

.builder-item {
  margin-top: 4px;
  margin-bottom: 4px; }

@media screen and (min-width: 960px) {
  .builder-item {
    margin-top: 8px;
    margin-bottom: 8px; } }

.header--row .header--row-inner,
.header--row .header--row-inner .container .row {
  display: flex;
  align-items: center; }

.header--row.layout-fullwidth .header--row-inner .container {
  max-width: 100% !important; }

.header--row.layout-contained {
  max-width: 1200px;
  margin: 0 auto; }

.footer--row .footer--row-inner,
.footer--row .footer--row-inner .container .row {
  display: flex;
  align-items: center; }

.footer--row.layout-fullwidth .footer--row-inner .container {
  max-width: 100% !important; }

.footer--row.layout-contained {
  max-width: 1200px;
  margin: 0 auto; }

.header--row-inner.header-top-inner.light-mode {
  background-color: #f0f0f0; }

.light-mode {
  background-color: #fff;
  color: #404248; }
  .light-mode a:not(.button) {
    color: #404248; }

.header-top-inner {
  font-size: .85em; }

.header--row-inner.dark-mode, .header--row-inner.dark-mode .sub-menu, .header--row-inner.dark-mode .sub-menu li {
  border-color: #333;
  background-color: #24292e;
  color: #fff; }

.header--row-inner.dark-mode a:not(.button) {
  color: #fff; }

.dark-mode .menu-item-nav-search, .dark-mode .menu-item-nav-cart > a {
  color: #fff; }

.hfg_header.site-header {
  border-bottom: 1px solid #f0f0f0; }

.hfg-item-right {
  text-align: right; }
  .hfg-item-right #secondary-menu, .hfg-item-right .nv-nav-wrap, .hfg-item-right .component-wrap, .hfg-item-right .site-logo {
    justify-content: flex-end; }

.hfg-item-left {
  text-align: left; }
  .hfg-item-left #secondary-menu, .hfg-item-left .nv-nav-wrap, .hfg-item-left .component-wrap, .hfg-item-left .site-logo {
    justify-content: flex-start; }

.hfg-item-center {
  text-align: center; }
  .hfg-item-center #secondary-menu, .hfg-item-center .nv-nav-wrap, .hfg-item-center .component-wrap, .hfg-item-center .site-logo {
    justify-content: center; }

body:before {
  transition: all .5s linear;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  content: " ";
  background: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0; }

body.menu_sidebar_dropdown:before {
  display: none; }

.header-menu-sidebar {
  padding: 0;
  position: fixed;
  width: 100%;
  max-width: 100%;
  top: 0;
  z-index: 999900;
  visibility: hidden; }
  .header-menu-sidebar .builder-item-sidebar {
    padding: 1.4em 1.5em;
    border-bottom: 1px solid transparent; }
    .header-menu-sidebar .builder-item-sidebar:last-of-type {
      border-bottom: none; }
    .header-menu-sidebar .builder-item-sidebar.mobile-item--primary-menu, .header-menu-sidebar .builder-item-sidebar.mobile-item--secondary-menu, .header-menu-sidebar .builder-item-sidebar.mobile-item--menu {
      padding: 0; }
  .header-menu-sidebar .nav-menu-mobile .nav-toggle-icon {
    color: currentColor; }
    .header-menu-sidebar .nav-menu-mobile .nav-toggle-icon:hover, .header-menu-sidebar .nav-menu-mobile .nav-toggle-icon:focus {
      background: rgba(0, 0, 0, 0.01); }
  .header-menu-sidebar.light-mode .sub-menu a,
  .header-menu-sidebar.light-mode .cart-item-link,
  .header-menu-sidebar.light-mode .item--html a,
  .header-menu-sidebar.light-mode .nav-menu a {
    color: rgba(0, 0, 0, 0.55); }
    .header-menu-sidebar.light-mode .sub-menu a:hover,
    .header-menu-sidebar.light-mode .cart-item-link:hover,
    .header-menu-sidebar.light-mode .item--html a:hover,
    .header-menu-sidebar.light-mode .nav-menu a:hover {
      color: rgba(0, 0, 0, 0.8); }
  .header-menu-sidebar.light-mode .cart-icon {
    color: rgba(0, 0, 0, 0.8); }
  .header-menu-sidebar.light-mode .builder-item-sidebar {
    border-color: rgba(0, 0, 0, 0.08); }
    .header-menu-sidebar.light-mode .builder-item-sidebar:before {
      background: rgba(0, 0, 0, 0.08); }
  .header-menu-sidebar.light-mode .header-menu-sidebar-bg:before {
    background: rgba(255, 255, 255, 0.9); }
  .header-menu-sidebar.light-mode .nav-menu-mobile .menu li a, .header-menu-sidebar.light-mode .sub-menu, .header-menu-sidebar.light-mode .nav-menu-mobile .nav-toggle-icon {
    border-color: rgba(0, 0, 0, 0.08); }
  .header-menu-sidebar.light-mode .nav-menu-mobile .nav-toggle-icon:hover, .header-menu-sidebar.light-mode .nav-menu-mobile .nav-toggle-icon:focus {
    background: rgba(0, 0, 0, 0.01); }
  .header-menu-sidebar.light-mode .menu li {
    border-color: rgba(0, 0, 0, 0.08); }
  .header-menu-sidebar.dark-mode .sub-menu a,
  .header-menu-sidebar.dark-mode .cart-item-link,
  .header-menu-sidebar.dark-mode .item--html a,
  .header-menu-sidebar.dark-mode .nav-menu a, .header-menu-sidebar.dark-mode .nav-menu .nav-toggle-icon {
    color: rgba(255, 255, 255, 0.79); }
    .header-menu-sidebar.dark-mode .sub-menu a:hover,
    .header-menu-sidebar.dark-mode .cart-item-link:hover,
    .header-menu-sidebar.dark-mode .item--html a:hover,
    .header-menu-sidebar.dark-mode .nav-menu a:hover, .header-menu-sidebar.dark-mode .nav-menu .nav-toggle-icon:hover {
      color: rgba(255, 255, 255, 0.99); }
  .header-menu-sidebar.dark-mode .cart-icon {
    color: rgba(255, 255, 255, 0.99); }
  .header-menu-sidebar.dark-mode .builder-item-sidebar {
    border-color: rgba(255, 255, 255, 0.08); }
    .header-menu-sidebar.dark-mode .builder-item-sidebar:before {
      background: rgba(255, 255, 255, 0.08); }
  .header-menu-sidebar.dark-mode .header-menu-sidebar-bg:before {
    background: rgba(0, 0, 0, 0.9); }
  .header-menu-sidebar.dark-mode .nav-menu-mobile .menu li a, .header-menu-sidebar.dark-mode .sub-menu, .header-menu-sidebar.dark-mode .nav-menu-mobile .nav-toggle-icon {
    border-color: rgba(255, 255, 255, 0.08); }
  .header-menu-sidebar.dark-mode .nav-menu-mobile .nav-toggle-icon:hover, .header-menu-sidebar.dark-mode .nav-menu-mobile .nav-toggle-icon:focus {
    background: rgba(0, 0, 0, 0.01); }
  .header-menu-sidebar.dark-mode .menu li {
    border-color: rgba(255, 255, 255, 0.08); }

.header-menu-sidebar-bg {
  position: relative;
  word-wrap: break-word;
  min-height: 100%;
  display: block; }
  .header-menu-sidebar-bg:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    min-height: 100%; }

.header-menu-sidebar-inner {
  position: relative;
  word-wrap: break-word;
  min-height: 100%;
  padding: 1.2em 0;
  display: flex;
  flex-direction: column; }

/* Type : Overlay */
.menu_sidebar_slide_overlay .header-menu-sidebar {
  box-shadow: none;
  transition: all .5s linear;
  z-index: 0;
  opacity: 0;
  top: 0;
  width: 100%;
  right: 0;
  bottom: 0; }

.menu_sidebar_slide_overlay .builder-item-sidebar {
  text-align: center;
  padding: 2em 1.5em;
  border: none;
  position: relative; }
  .menu_sidebar_slide_overlay .builder-item-sidebar:before {
    content: "";
    width: 8%;
    height: 2px;
    display: block;
    background: rgba(255, 255, 255, 0.08);
    position: absolute;
    bottom: 0;
    left: 46%; }
  .menu_sidebar_slide_overlay .builder-item-sidebar:last-child:before {
    content: none; }
  .menu_sidebar_slide_overlay .builder-item-sidebar[class*="-menu"] {
    padding: 2.5em 1.5em; }

.menu_sidebar_slide_overlay .nav-menu-mobile .menu li a {
  border: none;
  font-size: 140%;
  text-transform: uppercase;
  font-weight: 500; }
  .menu_sidebar_slide_overlay .nav-menu-mobile .menu li a .nav-toggle-icon {
    display: inline-block;
    position: relative;
    border: 0 none;
    margin-right: -1.3em;
    top: -2px;
    padding: 0.28em 0.5em; }

.menu_sidebar_slide_overlay .nav-menu-mobile .sub-menu {
  border: none; }
  .menu_sidebar_slide_overlay .nav-menu-mobile .sub-menu li a {
    text-transform: none;
    padding: 0.35em 1.5em;
    font-size: 100%; }

.menu_sidebar_slide_overlay .header-menu-sidebar-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; }

.menu_sidebar_slide_overlay .header-menu-sidebar-inner {
  max-width: 48em;
  margin: 3em auto;
  padding: 0; }

.menu_sidebar_slide_overlay .dark-mode .header-menu-sidebar-bg:before {
  background: rgba(0, 0, 0, 0.9); }

.menu_sidebar_slide_overlay .light-mode.close-sidebar-panel .hamburger-inner {
  background: #404248; }
  .menu_sidebar_slide_overlay .light-mode.close-sidebar-panel .hamburger-inner:before, .menu_sidebar_slide_overlay .light-mode.close-sidebar-panel .hamburger-inner::after {
    background: inherit; }

.menu_sidebar_slide_overlay .light-mode.close-sidebar-panel:hover .hamburger-inner {
  background: rgba(0, 0, 0, 0.8); }

/* Type: Slide from left */
.menu_sidebar_slide_left .header-menu-sidebar {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  width: 360px;
  left: 0;
  transform: translate3d(-100%, 0, 0);
  transition: transform 0.5s cubic-bezier(0.79, 0.14, 0.15, 0.86); }

/* Type: Slide from right */
.menu_sidebar_slide_right .header-menu-sidebar {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  transition: transform 0.5s cubic-bezier(0.79, 0.14, 0.15, 0.86);
  transform: translate3d(200%, 0, 0);
  width: 360px;
  right: 0; }

/* Type: Toggle dropdown */
.menu_sidebar_dropdown .header-menu-sidebar {
  position: relative;
  height: 0;
  display: block;
  visibility: visible;
  z-index: 999900;
  overflow: hidden; }

.menu_sidebar_dropdown .header-menu-sidebar-inner {
  max-width: 48em;
  margin: 0 auto; }

.menu_sidebar_dropdown .header-menu-sidebar-bg:before {
  box-shadow: inset 0 0 12px 0 rgba(0, 0, 0, 0.05); }

/* Hiding Menu Sidebar animation. */
.hiding-header-menu-sidebar {
  overflow-x: hidden;
  position: relative;
  width: 100%;
  display: block; }
  .hiding-header-menu-sidebar.menu_sidebar_slide_overlay .header-menu-sidebar {
    z-index: 0;
    opacity: 0;
    height: 100%; }
  .hiding-header-menu-sidebar.menu_sidebar_slide_left .header-menu-sidebar {
    height: 100%;
    transform: translateX(-100%);
    transition: transform 0.5s cubic-bezier(0.79, 0.14, 0.15, 0.86); }
  .hiding-header-menu-sidebar .header-menu-sidebar {
    visibility: visible; }
  .hiding-header-menu-sidebar.menu_sidebar_slide_right .header-menu-sidebar {
    transform: translateX(200%);
    height: 100%; }

/* Showing Menu Sidebar animation. */
.is-menu-sidebar, .is-mobile-form-panel {
  overflow-x: hidden; }

.is-menu-sidebar .header-menu-sidebar {
  overflow: auto; }

.is-menu-sidebar.menu_sidebar_slide_overlay {
  overflow: initial;
  position: relative;
  width: 100%;
  display: block; }
  .is-menu-sidebar.menu_sidebar_slide_overlay .header-menu-sidebar {
    z-index: 999900;
    opacity: 1;
    visibility: visible; }
  .is-menu-sidebar.menu_sidebar_slide_overlay .menu-mobile-toggle {
    opacity: 0; }

.is-menu-sidebar.menu_sidebar_slide_left {
  width: 100%;
  display: block; }
  .is-menu-sidebar.menu_sidebar_slide_left .header-menu-sidebar {
    z-index: 999900;
    height: 100vh;
    transform: translate3d(0, 0, 0);
    left: 0;
    visibility: visible; }

.is-menu-sidebar.menu_sidebar_slide_right {
  overflow: initial;
  position: relative;
  width: 100%;
  display: block; }
  .is-menu-sidebar.menu_sidebar_slide_right .header-menu-sidebar {
    z-index: 999900;
    height: 100vh;
    max-width: 100vw;
    transform: translate3d(0, 0, 0);
    right: 0;
    visibility: visible; }

.is-menu-sidebar.menu_sidebar_dropdown .header-menu-sidebar {
  z-index: 10;
  visibility: visible; }

.header-menu-sidebar .menu-item-nav-search {
  display: flex;
  align-items: center; }

.header-menu-sidebar .menu-item-nav-search.responsive-nav-search .nv-nav-search {
  margin-left: 20px;
  position: relative;
  background-color: transparent;
  border: 0;
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  padding: 0;
  line-height: 1; }
  .header-menu-sidebar .menu-item-nav-search.responsive-nav-search .nv-nav-search .close-container {
    position: static;
    top: unset;
    width: 35%;
    padding: 0; }
  .header-menu-sidebar .menu-item-nav-search.responsive-nav-search .nv-nav-search form input {
    background-color: #fff; }

/* Close Button */
.header-menu-sidebar .navbar-toggle-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  z-index: 999910;
  padding: 8px 10px; }
  .header-menu-sidebar .navbar-toggle-wrapper button.navbar-toggle {
    padding: 8px 10px; }

.is-menu-sidebar:before, .is-mobile-form-panel:before {
  z-index: 999897;
  visibility: visible;
  opacity: 1; }

/* - Item showing animation  */
.header-menu-sidebar .item--inner {
  transition: transform .3s, opacity .3s;
  transform: translateY(45px);
  opacity: 0; }

.is-menu-sidebar .header-menu-sidebar .item--inner, .is-mobile-form-panel .header-menu-sidebar .item--inner {
  opacity: 1;
  transform: translateY(0); }

@media (max-width: 960px) {
  .footer--row .builder-item {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    margin-left: 0; }
    .footer--row .builder-item:not(:last-child) {
      margin-bottom: 30px; }
  .nv-footer-content {
    flex-wrap: wrap; } }

.site-footer {
  position: relative;
  z-index: 10; }
  .site-footer .item--inner {
    width: 100%;
    max-width: 100%; }
  .site-footer .builder-item {
    align-items: center;
    display: flex; }

.footer-top .light-mode {
  background: #fff; }

.footer-top .dark-mode {
  background: #292929; }

.footer-main .footer--row-inner {
  padding-top: 2.5em;
  padding-bottom: 2.5em; }

.footer-main .light-mode {
  background: #f9f9f9; }

.footer-main .dark-mode {
  background: #24292e; }

.footer-bottom .light-mode {
  background: #ededed; }

.footer-bottom .dark-mode {
  background: #24292e; }

.footer--row-inner.light-mode {
  color: #404248; }

.footer--row-inner.dark-mode {
  color: rgba(255, 255, 255, 0.99); }
  .footer--row-inner.dark-mode a:not(.button) {
    color: rgba(255, 255, 255, 0.79); }
    .footer--row-inner.dark-mode a:not(.button):hover {
      color: rgba(255, 255, 255, 0.99); }
  .footer--row-inner.dark-mode .footer-menu li a {
    color: inherit; }
  .footer--row-inner.dark-mode .product_list_widget li {
    border-color: rgba(255, 255, 255, 0.08); }
  .footer--row-inner.dark-mode table tbody td,
  .footer--row-inner.dark-mode table th {
    background: rgba(0, 0, 0, 0.08); }

.footer--row.layout-fullwidth .hfg-container {
  max-width: initial; }

.footer--row .builder-item--group {
  justify-content: flex-start; }
  .footer--row .builder-item--group .item--inner {
    width: auto; }

.footer-main .builder-item .widget-area .widget:last-child, .footer-top .builder-item .widget-area .widget:last-child, .footer-bottom .builder-item .widget-area .widget:last-child {
  margin-bottom: 0; }

.site-footer p:last-child {
  margin-bottom: 0; }

.site-footer ul, .site-footer li {
  list-style: none;
  margin: 0; }

.site-footer ul ul {
  margin-left: 2.617924em; }

.footer-copyright {
  font-size: 0.875em; }

/* Logo & Site Identity */
.site-branding {
  display: flex;
  line-height: .9;
  vertical-align: middle; }
  .site-branding a {
    display: inline-block;
    letter-spacing: 0.4px;
    font-weight: 600; }
  .site-branding img {
    display: block;
    width: auto;
    line-height: 1; }
  .site-branding .site-title {
    font-size: 1.5em;
    margin: 0;
    font-weight: 600;
    line-height: 1.216; }
  .site-branding .site-description {
    margin: 5px 0px 7px 0px; }
  .site-branding.logo-left .logo-link {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .site-branding.logo-left .site-name-desc {
    margin-left: 7px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .site-branding.logo-right {
    flex-direction: row-reverse; }
    .site-branding.logo-right .logo-link {
      display: flex;
      flex-direction: column;
      justify-content: center; }
    .site-branding.logo-right .site-name-desc {
      margin-right: 7px;
      display: flex;
      flex-direction: column;
      justify-content: center; }
  .site-branding.logo-top {
    flex-direction: column; }
  .site-branding.logo-bottom {
    flex-direction: column-reverse; }
    .site-branding.logo-bottom .site-name-desc {
      margin-top: 7px; }

.item-button {
  display: inline-block; }

.site-header .cb-row--mobile .site-branding img {
  max-width: 100%; }

.style-border-top ul > li:not(.menu-item-nav-search):not(.menu-item-nav-cart) > a:after, .style-border-bottom ul > li:not(.menu-item-nav-search):not(.menu-item-nav-cart) > a:after, .style-full-height ul > li:not(.menu-item-nav-search):not(.menu-item-nav-cart) > a:after {
  position: absolute;
  content: "";
  display: block;
  margin: 0 auto;
  width: 0;
  height: 2px;
  background-color: #0366d6;
  transition: opacity .3s ease, width .3s ease; }

.style-border-top ul > li:hover > a, .style-border-bottom ul > li:hover > a, .style-full-height ul > li:hover > a {
  text-decoration: none; }
  .style-border-top ul > li:hover > a:after, .style-border-bottom ul > li:hover > a:after, .style-full-height ul > li:hover > a:after {
    width: 100% !important; }

.style-border-top ul > li.menu-item-nav-cart:hover a, .style-border-top ul > li.menu-item-nav-search:hover a, .style-border-bottom ul > li.menu-item-nav-cart:hover a, .style-border-bottom ul > li.menu-item-nav-search:hover a, .style-full-height ul > li.menu-item-nav-cart:hover a, .style-full-height ul > li.menu-item-nav-search:hover a {
  opacity: .75; }

.woocommerce-mini-cart-item > a:after {
  content: none !important; }

.style-border-bottom a:after {
  bottom: 0; }

.style-border-bottom .sub-menu a:after {
  bottom: 5px; }

.style-border-top a:after {
  top: 0; }

.style-border-top .sub-menu a:after {
  top: 5px; }

.style-full-height a {
  padding-left: 3px;
  padding-right: 3px;
  position: relative;
  z-index: 1; }
  .style-full-height a:after {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto !important;
    z-index: -1; }

.style-full-height .sub-menu a:after {
  top: 5px;
  bottom: 5px; }

#secondary-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  #secondary-menu li {
    position: relative;
    width: inherit; }
    #secondary-menu li a {
      display: flex;
      justify-content: center;
      padding: 0 10px;
      font-weight: 500; }
  #secondary-menu i {
    font-size: inherit;
    line-height: inherit;
    width: 20px;
    margin-top: 0; }
  .header-menu-sidebar #secondary-menu {
    flex-direction: column; }
    .header-menu-sidebar #secondary-menu li a {
      padding: 5px 0; }

.header-menu-sidebar .nav-menu-primary ul {
  flex-direction: column;
  display: flex; }
  .header-menu-sidebar .nav-menu-primary ul li {
    background-color: transparent;
    padding: 3px 0; }
    .header-menu-sidebar .nav-menu-primary ul li a {
      padding: 3px 0; }
  .header-menu-sidebar .nav-menu-primary ul .sub-menu {
    background-color: transparent; }

.nav-menu-footer a {
  display: flex;
  justify-content: center;
  position: relative; }

/* Nav Icon */
.menu-mobile-toggle {
  cursor: pointer;
  text-align: center;
  background: transparent;
  box-shadow: none;
  color: currentColor;
  transition: transform .3s, border .3s, background .3s, box-shadow .3s, opacity .3s, color .3s; }
  .menu-mobile-toggle:hover {
    box-shadow: none;
    background: transparent; }

.nav-icon--label {
  background: none !important;
  text-transform: uppercase;
  font-size: 0.85em; }

.hamburger {
  padding: 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }

.hamburger-box {
  display: inline-block;
  position: relative;
  vertical-align: middle; }

.hamburger-inner {
  display: block;
  top: 50%;
  background-color: currentcolor; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    border-radius: 0px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block;
    background-color: inherit; }

.is-size-small .hamburger .hamburger-box {
  margin-top: -2px;
  width: 19px; }

.is-size-small .hamburger .hamburger-inner {
  margin-top: -1px; }
  .is-size-small .hamburger .hamburger-inner, .is-size-small .hamburger .hamburger-inner::before, .is-size-small .hamburger .hamburger-inner::after {
    width: 19px;
    height: 2px; }
  .is-size-small .hamburger .hamburger-inner::before {
    top: -6px; }
  .is-size-small .hamburger .hamburger-inner::after {
    bottom: -6px; }

.is-size-medium .hamburger .hamburger-box {
  margin-top: -2px;
  width: 22px; }

.is-size-medium .hamburger .hamburger-inner {
  margin-top: -1px; }
  .is-size-medium .hamburger .hamburger-inner, .is-size-medium .hamburger .hamburger-inner::before, .is-size-medium .hamburger .hamburger-inner::after {
    width: 22px;
    height: 2px; }
  .is-size-medium .hamburger .hamburger-inner::before {
    top: -7px; }
  .is-size-medium .hamburger .hamburger-inner::after {
    bottom: -7px; }

.is-size-large .hamburger .hamburger-box {
  margin-top: -2px;
  width: 31px; }

.is-size-large .hamburger .hamburger-inner {
  margin-top: -1.5px; }
  .is-size-large .hamburger .hamburger-inner, .is-size-large .hamburger .hamburger-inner::before, .is-size-large .hamburger .hamburger-inner::after {
    width: 31px;
    height: 3px; }
  .is-size-large .hamburger .hamburger-inner::before {
    top: -9px; }
  .is-size-large .hamburger .hamburger-inner::after {
    bottom: -9px; }

@media screen and (min-width: 960px) {
  .is-size-desktop-small .hamburger .hamburger-box {
    margin-top: -2px;
    width: 19px; }
  .is-size-desktop-small .hamburger .hamburger-inner {
    margin-top: -1px; }
    .is-size-desktop-small .hamburger .hamburger-inner, .is-size-desktop-small .hamburger .hamburger-inner::before, .is-size-desktop-small .hamburger .hamburger-inner::after {
      width: 19px;
      height: 2px; }
    .is-size-desktop-small .hamburger .hamburger-inner::before {
      top: -6px; }
    .is-size-desktop-small .hamburger .hamburger-inner::after {
      bottom: -6px; }
  .is-size-desktop-medium .hamburger .hamburger-box {
    margin-top: -2px;
    width: 22px; }
  .is-size-desktop-medium .hamburger .hamburger-inner {
    margin-top: -1px; }
    .is-size-desktop-medium .hamburger .hamburger-inner, .is-size-desktop-medium .hamburger .hamburger-inner::before, .is-size-desktop-medium .hamburger .hamburger-inner::after {
      width: 22px;
      height: 2px; }
    .is-size-desktop-medium .hamburger .hamburger-inner::before {
      top: -7px; }
    .is-size-desktop-medium .hamburger .hamburger-inner::after {
      bottom: -7px; }
  .is-size-desktop-large .hamburger .hamburger-box {
    margin-top: -2px;
    width: 31px; }
  .is-size-desktop-large .hamburger .hamburger-inner {
    margin-top: -1.5px; }
    .is-size-desktop-large .hamburger .hamburger-inner, .is-size-desktop-large .hamburger .hamburger-inner::before, .is-size-desktop-large .hamburger .hamburger-inner::after {
      width: 31px;
      height: 3px; }
    .is-size-desktop-large .hamburger .hamburger-inner::before {
      top: -9px; }
    .is-size-desktop-large .hamburger .hamburger-inner::after {
      bottom: -9px; } }

@media screen and (max-width: 959px) {
  .is-size-tablet-small .hamburger .hamburger-box {
    margin-top: -2px;
    width: 19px; }
  .is-size-tablet-small .hamburger .hamburger-inner {
    margin-top: -1px; }
    .is-size-tablet-small .hamburger .hamburger-inner, .is-size-tablet-small .hamburger .hamburger-inner::before, .is-size-tablet-small .hamburger .hamburger-inner::after {
      width: 19px;
      height: 2px; }
    .is-size-tablet-small .hamburger .hamburger-inner::before {
      top: -6px; }
    .is-size-tablet-small .hamburger .hamburger-inner::after {
      bottom: -6px; }
  .is-size-tablet-medium .hamburger .hamburger-box {
    margin-top: -2px;
    width: 22px; }
  .is-size-tablet-medium .hamburger .hamburger-inner {
    margin-top: -1px; }
    .is-size-tablet-medium .hamburger .hamburger-inner, .is-size-tablet-medium .hamburger .hamburger-inner::before, .is-size-tablet-medium .hamburger .hamburger-inner::after {
      width: 22px;
      height: 2px; }
    .is-size-tablet-medium .hamburger .hamburger-inner::before {
      top: -7px; }
    .is-size-tablet-medium .hamburger .hamburger-inner::after {
      bottom: -7px; }
  .is-size-tablet-large .hamburger .hamburger-box {
    margin-top: -2px;
    width: 31px; }
  .is-size-tablet-large .hamburger .hamburger-inner {
    margin-top: -1.5px; }
    .is-size-tablet-large .hamburger .hamburger-inner, .is-size-tablet-large .hamburger .hamburger-inner::before, .is-size-tablet-large .hamburger .hamburger-inner::after {
      width: 31px;
      height: 3px; }
    .is-size-tablet-large .hamburger .hamburger-inner::before {
      top: -9px; }
    .is-size-tablet-large .hamburger .hamburger-inner::after {
      bottom: -9px; } }

@media screen and (max-width: 576px) {
  .is-size-mobile-small .hamburger .hamburger-box {
    margin-top: -2px;
    width: 19px; }
  .is-size-mobile-small .hamburger .hamburger-inner {
    margin-top: -1px; }
    .is-size-mobile-small .hamburger .hamburger-inner, .is-size-mobile-small .hamburger .hamburger-inner::before, .is-size-mobile-small .hamburger .hamburger-inner::after {
      width: 19px;
      height: 2px; }
    .is-size-mobile-small .hamburger .hamburger-inner::before {
      top: -6px; }
    .is-size-mobile-small .hamburger .hamburger-inner::after {
      bottom: -6px; }
  .is-size-mobile-medium .hamburger .hamburger-box {
    margin-top: -2px;
    width: 22px; }
  .is-size-mobile-medium .hamburger .hamburger-inner {
    margin-top: -1px; }
    .is-size-mobile-medium .hamburger .hamburger-inner, .is-size-mobile-medium .hamburger .hamburger-inner::before, .is-size-mobile-medium .hamburger .hamburger-inner::after {
      width: 22px;
      height: 2px; }
    .is-size-mobile-medium .hamburger .hamburger-inner::before {
      top: -7px; }
    .is-size-mobile-medium .hamburger .hamburger-inner::after {
      bottom: -7px; }
  .is-size-mobile-large .hamburger .hamburger-box {
    margin-top: -2px;
    width: 31px; }
  .is-size-mobile-large .hamburger .hamburger-inner {
    margin-top: -1.5px; }
    .is-size-mobile-large .hamburger .hamburger-inner, .is-size-mobile-large .hamburger .hamburger-inner::before, .is-size-mobile-large .hamburger .hamburger-inner::after {
      width: 31px;
      height: 3px; }
    .is-size-mobile-large .hamburger .hamburger-inner::before {
      top: -9px; }
    .is-size-mobile-large .hamburger .hamburger-inner::after {
      bottom: -9px; } }

/*
* Squeeze
*/
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--squeeze .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease; }
  .hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease; }
  .hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

.icon-bar {
  transition: all .1s ease;
  position: relative;
  display: block;
  border-radius: 2px;
  width: 15px;
  height: 2px; }
  .icon-bar:nth-child(2) {
    margin: 3px 0; }
  .light-mode .icon-bar {
    background: #404248; }
  .dark-mode .icon-bar {
    background: #fff; }

.navbar-toggle-wrapper {
  align-items: center; }

.navbar-toggle {
  border-radius: 3px;
  background: none;
  cursor: pointer;
  padding: 10px 15px;
  display: flex;
  align-items: center; }
  .navbar-toggle:hover {
    box-shadow: none;
    transform: none;
    background: transparent; }
  .navbar-toggle .nav-icon--label {
    margin-left: 5px; }
  .light-mode .navbar-toggle {
    border: 1px solid #f0f0f0;
    color: #404248; }
  .dark-mode .navbar-toggle {
    padding: 10px 0; }

.is-menu-sidebar .navbar-toggle .icon-bar:nth-child(1) {
  transform: rotate(45deg);
  top: 5px; }

.is-menu-sidebar .navbar-toggle .icon-bar:nth-child(2) {
  opacity: 0; }

.is-menu-sidebar .navbar-toggle .icon-bar:nth-child(3) {
  transform: rotate(-45deg);
  bottom: 5px; }

.builder-item--header_search {
  line-height: 1; }
  .builder-item--header_search .widget {
    width: 100%; }
  .builder-item--header_search input[type="search"] {
    background-color: #fff; }

.nv-search-icon-component a {
  padding: 10px 0; }

/* Customize Preview */
.customize-previewing {
  overflow-x: hidden; }
  .customize-previewing .builder-item-focus.item--inner {
    position: relative; }
    .customize-previewing .builder-item-focus.item--inner.has_menu .item--preview-name {
      left: auto; }
  .customize-previewing .builder-item-focus .item--preview-name {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    background: #0073aa;
    color: #fff;
    padding: 5px 8px 6px;
    font-size: 12px !important;
    font-weight: 600;
    line-height: 14px;
    cursor: pointer;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px; }
  .customize-previewing .builder-item-focus:hover.item--inner {
    transition-delay: 0s !important;
    outline: 1px solid #0073aa;
    outline-offset: -1px; }
    .customize-previewing .builder-item-focus:hover.item--inner .item--preview-name {
      display: block;
      z-index: 9999999; }
  .customize-previewing .header--row .customize-partial-edit-shortcut,
  .customize-previewing .header-menu-sidebar .customize-partial-edit-shortcut {
    display: none; }
  .customize-previewing .footer--row .builder-item-focus:hover.item--inner {
    position: relative;
    outline: 1px dashed #ff472e;
    outline-offset: -1px; }

.hfg-grid {
  display: flex; }

/* Customizer Shortcut */
#header-menu-sidebar .customize-partial-edit-shortcut {
  display: none; }

@media screen and (min-width: 960px) {
  .hide-on-desktop,
  .hfg-grid .hide-on-desktop {
    display: none; }
  .align-left-desktop {
    text-align: left; }
  .align-center-desktop {
    text-align: center; }
  .align-right-desktop {
    text-align: right; } }

@media screen and (max-width: 959px) {
  .hide-on-tablet,
  .hfg-grid .hide-on-tablet {
    display: none; }
  .align-left-tablet {
    text-align: left; }
  .align-center-tablet {
    text-align: center; }
  .align-right-tablet {
    text-align: right; } }

@media screen and (max-width: 576px) {
  .hide-on-mobile,
  .hfg-grid .hide-on-mobile {
    display: none; }
  .align-left-mobile {
    text-align: left; }
  .align-center-mobile {
    text-align: center; }
  .align-right-mobile {
    text-align: right; } }

.builder-item.hfg-edge-left .item--inner > div {
  flex-direction: row;
  display: flex; }

.builder-item.hfg-edge-right .item--inner > div {
  flex-direction: row-reverse;
  display: flex; }

.footer--row .nv-footer-content, .footer--row .nv-footer-widgets {
  padding: 0; }

.component-wrap {
  display: flex;
  margin: 4px 0; }

.nv-html-content p {
  margin: 0; }
